import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import Resources from '../components/resources'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
} from '../lib/helpers'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query ResourcesPageQuery($id: String!) {
    
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      comingSoon
      email
      author
      keywords
    }
    
    resources: sanityResources(title: {id: {eq: $id}}) {
      image {
        ...SanityImage
        alt
        caption
      }
      links {
        title
        url
      }
      _rawDescription
    }

    festival: sanityYear(id: {eq: $id}) {
      _rawIntroduction
      _rawComingSoon
      _rawCall
      title
      beginDate
      endDate
      horizontalLogo {
        ...SanityImage
        alt
      }
      stackedLogo {
        ...SanityImage
        alt
      }
      siteImage {
        ...SanityImage
        alt
      }
      colorA
      colorB
      colorC
      bodyFont
      secondaryFont
      links {
        title
        url
      }
      partners {
        title
        url
        image {
          ...SanityImage
          alt
        }
      }
    }

  }
`

const ResourcesPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const resources = (data || {}).resources
  const festival = (data || {}).festival

  return (
    <Layout 
      title={site.title} 
      email={site.email} 
      footer={site.footer} 
      partners={festival.partners} 
      colorA={festival.colorA} 
      colorB={festival.colorB} 
      colorC={festival.colorC} 
      bodyFont={festival.bodyFont} 
      secondaryFont={festival.secondaryFont} 
      horizontalLogo={festival.horizontalLogo}
      instagramLink={site.instagramLink} 
      facebookLink={site.facebookLink} 
      twitter={site.twitter} 
      linkedInLink={site.linkedInLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
        image={festival.siteImage}
      />
      <Container>
        
        {resources && resources._rawDescription && resources.links  && resources.image &&  (
          <Resources
            title='LOCO Resources'
            body={resources._rawDescription}
            image={resources.image}
            links={resources.links}
          />
        )}

      </Container>
    </Layout>
  )
}

export default ResourcesPage