import React from 'react'
import "../styles/resources.css"
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

function Resources (props) {
const {image, body, title, links} = props

return (

  <div id="resources-outer">
      <div className="wrapper">
        <div id="resources-inner">

          <h2>{title}</h2>
          <div id="resources-holder" class="grid resources">
            <div className="width-3-12-m width-4-12">

              {props.image && image.asset && (
                <div className="image-holder">

                        <img
                          src={imageUrlFor(buildImageObj(props.image))
                            .width(1200)
                            .auto('format')
                            .url()}
                          alt={props.image.alt}
                          title={props.image.alt}
                        />
                  {image.caption && (
                    <span className="caption">
                      {image.caption}
                    </span>
                  )}

                </div>
              )}

            </div>
            <div className="width-9-12-m width-8-12 page">   

              {body && (
                <div className="body-holder">
                    {body && <PortableText blocks={body} />}
                  </div>
                )}

              {links &&
                  links.map(link => (
                  <p>
                    <a target="_blank" rel="noreferrer" title={link.title} href={link.url}>
                      {link.title}
                    </a>
                  </p>    
                  ))
                }  

            </div>
          </div>
        </div>
      </div>
  </div>

)}

export default Resources